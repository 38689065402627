<template>
  <v-hover v-slot:default="{ hover }" close-delay="200">
    <v-card class="custom-card text-center" :elevation="hover ? 16 : 2" flat>
      <v-icon class="pt-4" x-large v-text="icon"></v-icon>
      <v-card-title v-text="title" />
      <v-card-subtitle class="text-start" v-text="subtitle" />
      <v-divider class="mx-auto colo" />
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "CustomCard",
  props: {
    step: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-card {
  .v-divider {
    font: 1px;
    background-color: #2d4574;
  }
}
</style>
